import HelpOutline from "@mui/icons-material/HelpOutline"
import Box from "@mui/material/Box"

import Menu from "./menu"
import { Desktop } from "../components/device"
import ChangeLanguageButton from "../molecules/change_language_button"
import useTranslation from "../hooks/use_translation"
import HelpLink from "@/molecules/help_link"

const styles = {
  separator: {
    height: "16px",
    width: "1px",
    marginLeft: "16px",
    marginRight: "16px",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}

const AppHeaderSide2 = () => {
  const { t } = useTranslation()

  return (
    <Box sx={styles.buttonsContainer}>
      <Desktop>
        <HelpLink href="/support">
          <HelpOutline />
          <Box>{t("help")}</Box>
        </HelpLink>
        <Box sx={styles.separator} />
      </Desktop>
      <Menu />
      <ChangeLanguageButton />
    </Box>
  )
}

AppHeaderSide2.propTypes = {}

export default AppHeaderSide2
