import { useState, useEffect } from "react"
import ThemeProvider from "@mui/material/styles/ThemeProvider"
import createTheme from "@mui/material/styles/createTheme"
import CssBaseline from "@mui/material/CssBaseline"
import { CacheProvider } from "@emotion/react"
import rtlPlugin from "stylis-plugin-rtl"
import { useSelector } from "react-redux"
import createCache from "@emotion/cache"
import { prefixer } from "stylis"
import useDirection from "../hooks/use_direction"

const longChatsLanguageVariables = {
  isLongCharWidth: true,
  fontSizes: {
    text: "15px",
    actionTitle: "17px",
    title: "22px",
  },
}

const defaultLanguageVariables = {
  isLongCharWidth: false,
  fontSizes: {
    text: "16px",
    actionTitle: "20px",
    title: "24px",
  },
}

const theme = createTheme({
  typography: {
    button: {
      textTransform: "none",
    },
  },
  variables: {
    isLtr: false,
    ...defaultLanguageVariables,
  },
  spacing: 1,
  palette: {
    default: {
      main: "rgb(0, 0, 0)",
      dark: "rgb(0, 0, 0)",
    },
  },
  breakpoints: {
    values: {
      mobile: 0,
      desktop: 731,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: "rgba(0, 0, 0, 0.05)",
        },
      },
    },
  },
})

const createRtlCache = () =>
  createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, rtlPlugin],
  })

const createLtrCache = () =>
  createCache({
    key: "muiltr",
  })

const Theme = ({ children }) => {
  const currentLanguageCode = useSelector((state) => state.currentLanguageCode)
  const languageDirection = useDirection()
  const isLtr = languageDirection === "ltr"

  const longCharWidthLanguages = ["ja", "en"]

  const [cache, setCache] = useState(isLtr ? createLtrCache() : createRtlCache())

  if (longCharWidthLanguages.includes(currentLanguageCode)) {
    Object.assign(theme.variables, longChatsLanguageVariables)
  } else {
    Object.assign(theme.variables, defaultLanguageVariables)
  }

  useEffect(() => {
    theme.variables.isLtr = isLtr
    document.dir = isLtr ? "ltr" : "rtl"
    setCache(isLtr ? createLtrCache() : createRtlCache())
  }, [isLtr, currentLanguageCode])

  return (
    <CacheProvider value={cache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </CacheProvider>
  )
}
export default Theme
